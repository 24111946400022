<template>
  <div class="text-left bg-lightgray py-4 flex justify-between lg:px-10 px-5">
    <h2 class="text-secondary text-2xl font-medium my-auto">Avances</h2>
    <template v-if="hasStages">
      <div class="py-2 text-left">
        <input
          type="checkbox"
          class="
            checked:bg-primary checked:border-transparent
            cursor-pointer
            ...
          "
          :checked="useFillAutomaticAdvances"
          @click="onSelectFillAutomatic"
        />
        <span class="select-none"> Llenado automático </span>
      </div>
      <button
        class="
          rounded
          border border-primary
          bg-primary
          py-2
          px-4
          text-white
          font-medium
          flex
        "
        @click="onOpenDrawerUploadAdvances"
      >
        Importar avances
      </button>
    </template>
  </div>

  <div v-if="stages" class="lg:p-10 flex align-start p-5">
    <div class="flex justify-between">
      <input
        v-model="dateFrom"
        type="date"
        min="1970-01-05"
        step="7"
        class="
          relative
          p-2
          bg-white
          rounded
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          focus:outline-none
          px-4
        "
      />
    </div>
    <div class="ml-4">
      <select
        v-model="filterDeparture"
        class="
          relative
          py-3
          px-2
          w-full
          bg-white
          rounded-sm
          border
          outline-none
          text-gray-700
          placeholder-gray-400
        "
        placeholder="Seleccione"
      >
        <option value="">Todas partidas</option>
        <option
          v-for="(departure, i) in getDeparturesIsToCalculateIp"
          :key="i"
          :value="departure.key"
        >
          {{ departure.name }}
        </option>
      </select>
    </div>
    <div v-if="hasStages" class="ml-4">
      <select
        v-model="filterStage"
        class="
          relative
          py-3
          px-2
          w-full
          bg-white
          rounded-sm
          border
          outline-none
          text-gray-700
          placeholder-gray-400
        "
        placeholder="Seleccione"
      >
        <option value="">Todas etapas</option>
        <option v-for="(stage, i) in stages" :key="i" :value="stage">
          {{ stage }}
        </option>
      </select>
    </div>
    <div class="ml-4">
      <button
        class="
          text-primary
          border
          text-sm
          font-medium
          border-primary
          p-2
          px-5
          h-full
        "
        @click="onFilter"
      >
        Filtrar
      </button>
    </div>
  </div>

  <div v-if="isLoading" class="flex justify-center">
    <img :src="require('@/assets/loader.gif')" width="250" />
  </div>

  <div v-else class="lg:px-10">
    <div class="grid grid-cols-9 pb-2">
      <div></div>
      <div
        v-for="(d, i) in weekDays"
        :key="i"
        class="text-secondary font-medium px-2"
      >
        {{ d }}
      </div>
    </div>
    <div
      v-for="(weekItem, weekItemInd) in weekItems"
      :key="weekItemInd"
      class="grid grid-cols-9 py-3"
    >
      <div class="flex flex-col my-auto">
        {{ weekItem.departure.code ? weekItem.departure.code : "" }}
        {{ weekItem.departure.name }} ({{ weekItem.departure.unit }})
        <span v-if="weekItem.sector">
          <span class="text-primary">Sector:</span> {{ weekItem.sector.name }}
          <br /><span class="text-primary">Etapa:</span>
          {{ weekItem.sector.stage }}
        </span>
      </div>
      <div
        v-for="(item, itemInd) in weekItem.items"
        :key="itemInd"
        class="text-secondary px-2"
      >
        <input
          v-if="weekItemInd === weekSelected"
          v-model.number="item.new_advance"
          type="text"
          class="
            relative
            py-3
            px-2
            w-full
            bg-white
            rounded
            shadow
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
          "
        />
        <p v-else class="px-2 pt-2">{{ item.advance }}</p>
      </div>
      <div
        v-if="weekSelected === weekItemInd || !weekSelected"
        class="text-center my-auto mx-2 justify-end pl-2"
      >
        <button
          v-if="weekSelected === weekItemInd"
          :disabled="loadingSave"
          class="rounded bg-primary py-2 px-8 text-white font-medium flex"
          type="text"
          @click="onSaveWeekDeparturesIp(weekItemInd)"
        >
          <LoadingButtonIcon v-if="loadingSave" />
          Guardar
        </button>
        <a
          v-else
          class="
            text-primary
            font-medium
            cursor-pointer
            text-right
            hover:bg-blue-50
            px-2
          "
          @click="weekSelected = weekItemInd"
        >
          Editar
        </a>
      </div>
    </div>
  </div>

  <EndDrawer
    :open="drawerUploadAdvances"
    @update:open="drawerUploadAdvances = $event"
  >
    <UploadAdvancesForm @onComplete="loadProductivityAdvances" />
  </EndDrawer>
</template>

<script setup>
import { ref, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import UploadAdvancesForm from "./forms/UploadAdvances";

const store = useStore();
const isLoading = ref(true);

const weekDays = ref([]);
const weekItems = ref([]);
const dateFrom = ref("");
const dateTo = ref("");
const filterDeparture = ref("");
const filterStage = ref("");
const loadingSave = ref(false);
const weekSelected = ref(null);
const useFillAutomaticAdvances = ref(false);
const stages = ref(null);
const drawerUploadAdvances = ref(false);

const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);
const getDeparturesIsToCalculateIp = computed(
  () => store.getters.getDeparturesIsToCalculateIp
);
const getSectors = computed(() => store.getters.getSectors);
const getDeparturesIp = computed(() => store.getters.getDeparturesIp);
const getDeparturesIpInfo = computed(() => store.getters.getDeparturesIpInfo);
const getWork = computed(() => store.getters.getWork);
// const filteredWeekItems = computed(() => {
//   return weekItems.value.filter((weekItem) => {
//     if (
//       filterDeparture.value &&
//       weekItem.departure.name !== filterDeparture.value
//     )
//       return false;

//     if (filterStage.value && weekItem.sector.stage !== filterStage.value)
//       return false;

//     return true;
//   });
// });

const hasSectors = computed(() => {
  return getWork.value && getWork.value.useSectors;
});

const hasStages = ref(false);

if (hasSectors.value) {
  const getSectors = computed(() => store.getters.getSectors);
  const stages = ref([]);

  onMounted(async () => {
    await store.dispatch("getAllSectors");
    stages.value = [
      ...new Set(getSectors.value?.filter((s) => s.stage).map((s) => s.stage)),
    ];

    hasStages.value = stages.value.length > 0;
  });
} else {
  hasStages.value = false;
}

const loadProductivityAdvances = async () => {
  try {
    isLoading.value = true;

    store.dispatch("resetDeparturesIp");

    let filter = {
      date: dateFrom.value,
      dateTo: dateTo.value,
    };

    if (filterDeparture.value) filter.departureKey = filterDeparture.value;
    if (filterStage.value) filter.stage = filterStage.value;

    await store.dispatch("getAllDeparturesIp", filter);
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

watch(
  () => dateFrom.value,
  () => {
    dateTo.value = dayjs(dateFrom.value).add(6, "day").format("YYYY-MM-DD");
  }
);

const orderByDeparture = (departuresIp) => {
  return departuresIp.sort((a, b) => {
    const codeA = a.departure.code;
    const codeB = b.departure.code;
    const nameA = a.departure.name;
    const nameB = b.departure.name;

    if (!codeA) return 1;
    if (!codeB) return -1;

    if (codeA < codeB) return -1;
    if (codeA > codeB) return 1;

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0; //
  });
};

watch(
  () => getDeparturesIp.value,
  (value) => {
    if (!value) return;

    let weekDeparturesIp = [];
    let jump = 0;
    let daysName = [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo",
    ];

    let countDepartures = getDeparturesIpInfo.value.totalDepartures;
    let countSectors = getDeparturesIpInfo.value.totalSectors;
    if (countSectors && useFillAutomaticAdvances.value) {
      let totalDeparturesIp = 0;
      jump = countDepartures * countSectors;

      for (
        let i = 0;
        i < countDepartures * countSectors * 7;
        i += countSectors * 7
      ) {
        for (let j = 0; j < countSectors; j++) {
          weekDeparturesIp[totalDeparturesIp] = {
            departure: value[i].departure,
            sector: value[i + j].sector,
            items: value
              .filter(
                (v) =>
                  v.departureKey === value[i].departureKey &&
                  v.sectorKey === value[i + j].sectorKey
              )
              .map((v) => ({
                ...v,
                new_advance: v.advance > 0 ? v.advance : null,
              })),
          };

          totalDeparturesIp++;
        }
      }
    } else {
      jump = countDepartures;

      for (let i = 0; i < countDepartures; i++) {
        weekDeparturesIp[i] = {
          departure: value[i].departure,
          items: value
            .filter((v) => v.departureKey === value[i].departureKey)
            .map((v) => ({
              ...v,
              new_advance: v.advance > 0 ? v.advance : null,
            })),
        };
      }
    }

    let days = [];

    for (let d = 0; d < 7; d++) {
      days[d] = `${daysName[d]} ${value[d * jump].date
        .slice(5)
        .replace("-", "/")}`;
    }

    weekDays.value = days;
    weekItems.value = orderByDeparture(weekDeparturesIp);
  }
);

const init = async () => {
  store.dispatch("getAllDepartures", { includeSubdepartures: true });

  if (!getWeekInitialDate.value)
    await store.dispatch("setCurrentWeekInitialDate");

  await store.dispatch("getAllSectors");

  stages.value = [
    ...new Set(getSectors.value?.filter((s) => s.stage).map((s) => s.stage)),
  ];

  dateFrom.value = getWeekInitialDate.value;
  useFillAutomaticAdvances.value =
    getWork.value.useFillAutomaticAdvances || false;

  isLoading.value = false;
};

init();

const onOpenDrawerUploadAdvances = () => {
  drawerUploadAdvances.value = true;
};

const onFilter = () => {
  loadProductivityAdvances();
};

const onSelectFillAutomatic = async () => {
  try {
    useFillAutomaticAdvances.value = !useFillAutomaticAdvances.value;

    await store.dispatch("updateWork", {
      work: {
        key: getWork.value.key,
        useFillAutomaticAdvances: useFillAutomaticAdvances.value,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const onSaveWeekDeparturesIp = async (weekDeparturesIp) => {
  try {
    loadingSave.value = true;
    const promises = [];

    const items = weekItems.value[weekDeparturesIp].items;
    items.forEach((item) => {
      if (item.key === "00000000-0000-0000-0000-000000000000") {
        if (item.new_advance > 0) {
          let departureIp = {
            advance: item.new_advance,
            departureKey: item.departureKey,
            date: item.date,
          };

          if (item.sectorKey) departureIp.sectorKey = item.sectorKey;

          promises.push(store.dispatch("createDepartureIp", { departureIp }));
        }
      } else if (item.advance !== item.new_advance) {
        promises.push(
          store.dispatch("updateDepartureIp", {
            departureIp: {
              key: item.key,
              advance: item.new_advance,
            },
          })
        );
      }
    });

    const response = await Promise.all(promises);
    response.forEach((r) => {
      let itemIndFound = items.findIndex((item) => item.key === r.key);
      if (itemIndFound < 0) {
        itemIndFound = items.findIndex(
          (item) => item.date === r.date && item.departureKey === r.departureKey
        );
      }

      weekItems.value[weekDeparturesIp].items[itemIndFound] = {
        ...weekItems.value[weekDeparturesIp].items[itemIndFound],
        advance: r.advance,
        new_advance: r.advance,
        key: r.key,
      };
    });

    weekSelected.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};
</script>
